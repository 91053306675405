<template>
    <div>
        <DxDataGrid
            :data-source="items"
            :show-borders="true"
            :column-auto-width="true"
        >
             <DxColumn
                caption="Categoría"
                data-field="name"
                :visible="true"
                :allow-hiding="false"
            />
          <DxColumn
              data-field="contract_sale"
              caption="VALOR DE VENTA"
              :format="customCurrencyFormat"
          />
          <DxColumn
              :width="130"
              data-field="contract_cost"
              caption="COSTE TEÓRICO"
              :format="customCurrencyFormat"
          />
          <DxColumn
              data-field="expected_costs_prevision"
              caption="PREV. INICIAL"
              :format="customCurrencyFormat"
          />
          <DxColumn
              data-field="expected_costs_live_prevision"
              caption="PREV. VIVA"
              :format="customCurrencyFormat"
          />
          <DxColumn :width="130" data-field="expected_costs_order" caption="PEDIDO" :format="customCurrencyFormat" />
          <DxColumn
              :width="130"
              data-field="expected_costs_pendent"
              caption="PENDIENTE"
              :format="customCurrencyFormat"
          />
          <DxColumn
              data-field="commited_order"
              caption="T. PEDIDO COMPROMETIDO"
              :format="customCurrencyFormat"
          />
          <DxColumn
              data-field="posted_invoices"
              caption="T. FACTURAS CONTABILIZADAS"
              :format="customCurrencyFormat"
          />
          <DxColumn
              data-field="ordered_costs"
              caption="T. COSTES PEDIDOS"
              :format="customCurrencyFormat"
          />
        </DxDataGrid>
    </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn
} from 'devextreme-vue/data-grid';

export default {
    components: {

        DxDataGrid,
        DxColumn,
    },
  props: {
    items: Array,
  },
  data(){
      return{
        customCurrencyFormat: {
          formatter: (value) => {
            if (typeof value !== 'number') return value;
            return value.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
          }
        },
      }
  }
}
</script>

<template>
  <div>
    <DxDataGrid
      id="gridContainer"
      ref="dataGrid"
      :data-source="filteredJobs"
      :show-borders="true"
      key-expr="id"
      :column-resizing-mode="nextColumn"
      :column-min-width="50"
      :column-auto-width="true"
      @exporting="exportToExcel"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
       <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Buscar..."
      />
      <DxColumnChooser :enabled="true" :mode="'select'" show-check-boxes-mode="always"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection :mode="'multiple'" />
      <DxColumn
        caption="JOB"
        data-field="name"
        :visible="true"
        :allow-hiding="false"
      />
      <DxColumn
          data-field="client_name"
          caption="CLIENTE"
          :width="200"
          :max-width="200"
      />
      <DxColumn
        data-field="division_name"
        caption="DIVISIÓN"
      />
     <DxColumn
        data-field="job_date"
        caption="FECHA"
        :sort-order="'desc'"
        :sort-index="0"
        data-type="date"
        format="dd/MM/yyyy"
       :allow-header-filtering="false"
      />
      <DxColumn
        data-field="contract_sale"
        caption="VALOR DE VENTA"
        :format="customCurrencyFormat"
       :allow-header-filtering="false"
      />
      <DxColumn
        :width="130"
        data-field="contract_cost"
        caption="COSTE TEÓRICO"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="expected_costs_prevision"
        caption="PREV. INICIAL"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="expected_costs_live_prevision"
        caption="PREV. VIVA"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
      />
      <DxColumn :width="130" data-field="expected_costs_order" caption="PEDIDO" :format="customCurrencyFormat" />
      <DxColumn
        :width="130"
        data-field="expected_costs_pendent"
        caption="PENDIENTE"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="commited_order"
        caption="T. PEDIDO COMPROMETIDO"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
        :width="200"
        :max-width="200"
      />
      <DxColumn
        data-field="posted_invoices"
        caption="T. FACTURAS CONTABILIZADAS"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
        :width="200"
        :max-width="200"
      />
      <DxColumn
        data-field="ordered_costs"
        caption="T. COSTES PEDIDOS"
        :format="customCurrencyFormat"
        :allow-header-filtering="false"
        :width="200"
        :max-width="200"
      />
      <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
          />
          <template #masterDetailTemplate="{ data }">
              <DetailTemplate
                  :items="data.data.items"
              />
          </template>
      <DxSummary>
        <DxTotalItem
            column="contract_sale"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
            :allow-sorting="true"
        />
        <DxTotalItem
            column="contract_cost"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
            :allow-sorting="true"
        />
        <DxTotalItem
            column="expected_costs_prevision"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="expected_costs_live_prevision"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="expected_costs_order"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="expected_costs_pendent"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="commited_order"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="posted_invoices"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
        <DxTotalItem
            column="ordered_costs"
            summary-type="sum"
            :value-format="customCurrencyFormat"
            display-format="{0}"
        />
      </DxSummary>
    </DxDataGrid>
  </div>
</template>

<script>
import JobCategoryApi from "@/api/job-category-api";
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxSummary,
  DxGrouping,
  DxGroupItem,
  DxTotalItem,
  DxSortByGroupSummaryInfo,
  DxPager,
  DxPopup,
  DxButton,
  DxColumnChooser,
  DxSearchPanel,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import DetailTemplate from './DetailTemplate.vue';

loadMessages(esMessages);
locale('es');

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxGroupItem,
    DxGrouping,
    DxTotalItem,
    DxSortByGroupSummaryInfo,
    DxPager,
    DxPopup,
    DxButton,
    DxColumnChooser,
    DxSearchPanel,
    DxExport,
    DxFilterRow,
    DxHeaderFilter,
    DxMasterDetail,
    DetailTemplate
  },
  data() {
    return {
      jobs: [],
      customCurrencyFormat: {
        formatter: (value) => {
          if (typeof value !== 'number') return value;
          return value.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
        }
      },
      showColumnChooser: false,
      selectedDivision: null,
      divisions: [],
    };
  },
  computed: {
    filteredJobs() {
      if (!this.selectedDivision) {
        return this.jobs;
      }
      return this.jobs.filter(job => job.division_name === this.selectedDivision);
    }
  },
  methods: {
    async getData() {
      try {
        const response = await JobCategoryApi.listJobsReports();
        this.jobs = response.data;
        this.divisions = [...new Set(this.jobs.map(job => job.division_name))];
      } catch (err) {
        console.log(err);
      }
    },
    exportToExcel(e) {
      const workbook = new Workbook();

      const dataGrid = this.$refs.dataGrid.instance;

      dataGrid.option('paging', { enabled: false });

      // Obtener todos los datos (incluso paginación)
      const dataSource = dataGrid.getDataSource();
        dataSource.load().then((allData) => { // <-- Obtiene TODOS los registros
          const worksheet = workbook.addWorksheet('Jobs');



            exportDataGrid({
              component: dataGrid,
              worksheet: worksheet,
              autoFilterEnabled: true,
              customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.column.dataField !== 'name' && (gridCell.rowType === 'groupFooter' || gridCell.rowType === 'totalFooter' || gridCell.rowType === 'group')) {
                  excelCell.value = undefined;
                }
              },
              keepColumnWidths: true, // Mantener anchos de columnas
              loadPanel: {
                enabled: true
              },
              selectedRowsOnly: true
            }).then(() => {
              workbook.removeWorksheet('Jobs');

              const worksheet2 = workbook.addWorksheet('Partidas');
              // Añadir cabeceras de columnas
              worksheet2.addRow([
                'JOB','CLIENTE', 'VALOR DE VENTA', 'COSTE TEÓRICO', 'PREV. INICIAL', 'PREV. VIVA', 'PEDIDO', 'PENDIENTE', 'T. PEDIDO COMPROMETIDO', 'T. FACTURAS CONTABILIZADAS', 'T. COSTES PEDIDOS'
              ]);

              // Determinar filas a exportar
              let rows = dataGrid.getSelectedRowsData();
              if (rows.length === 0) {
                rows = allData; // Usar todos los datos cargados
              }

              // Añadir filas de la variable items
              rows.forEach((job) => {
                const jobRow = [
                  job.name,
                  job.client_name,
                  job.contract_sale,
                  job.contract_cost,
                  job.expected_costs_prevision,
                  job.expected_costs_live_prevision,
                  job.expected_costs_order,
                  job.expected_costs_pendent,
                  job.commited_order,
                  job.posted_invoices,
                  job.ordered_costs
                ];

                worksheet2.addRow(jobRow).font = { bold: true };

                if (job.items && Array.isArray(job.items)) {
                  job.items.forEach((item) => {
                    const itemRow = [
                      item.name,
                        '',
                      item.contract_sale,
                      item.contract_cost,
                      item.expected_costs_prevision,
                      item.expected_costs_live_prevision,
                      item.expected_costs_order,
                      item.expected_costs_pendent,
                      item.commited_order,
                      item.posted_invoices,
                      item.ordered_costs
                    ];
                    worksheet2.addRow(itemRow);
                  });
                }
              });

              // Ajustar anchos de columnas
              worksheet2.columns.forEach(column => {
                column.width = 20;
              });

              workbook.xlsx.writeBuffer().then((buffer) => {
                const fileName = `Jobs_${new Date().toISOString().slice(0, 10)}.xlsx`;
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);

              }).finally(() => {
                // Restaurar la paginación
                dataGrid.option('paging', { enabled: true });
              });

            });
          });
          e.cancel = true; // Evita la exportación predeterminada
        },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.dx-select-checkbox{
  display: block !important;
}
</style>

import { BaseApi } from '@/api/base-api'
import { RESOURCE_JOBS_CATEGORY } from '@/shared/constants/resources'
import Vue from 'vue'

class JobCategoryApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  listJobsReports() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/list`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener la información. Por favor, inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }


}

export default new JobCategoryApi(RESOURCE_JOBS_CATEGORY)
